import React, { FC } from "react";
import { sc } from "~/foundation/Jss";
import { OneColumnProps } from "../generated-types";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { GenericHeader } from "~/foundation/Components/GenericHeader";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { themable } from "~/foundation/Theme";

const OneColumn: FC<OneColumnProps> = ({ rendering, fields }) => {
	return (
		<Ribbon
			pt={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
			pb={["calc(var(--chakra-sizes-modulePY__SM) - 4rem)", null, "calc(var(--chakra-sizes-modulePY__MD) - 3rem)", null, "calc(var(--chakra-sizes-modulePY__XL) - 3rem)"]}
		>
			<ContentWrapper py={["0", null]}>
				<GenericHeader {...fields}/>
			</ContentWrapper>
			<sc.Placeholder name={rendering.placeholderNames.one_column} rendering={rendering} />
		</Ribbon>
	);
};

export default themable()(OneColumn);